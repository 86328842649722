import React from "react";
import "bulma/css/bulma.css";
import { Link } from "gatsby";
import Logo from "../../static/logo.svg";
import BlackLogo from "../../static/logowhite.svg";
import Burger from "../../static/burger.svg";
import BurgerWhite from "../../static/burgerwhite.svg";
import "../styles/mobileNavbar.css";
import { UnmountClosed } from "react-collapse";
import MobileWearable from "../components/mobileWearable";
import MobileCollection from "../components/mobileCollection";
import MobileCollaboration from "../components/mobileCollaboration";

class WearableLink extends React.Component {
  render() {
    if (this.props.checker.node.frontmatter.belongsTo === "wearable") {
      return (
        <Link
          to={this.props.checker.node.frontmatter.path}
          className="hiddenSideLink"
        >
          <li className="sidee">{this.props.checker.node.frontmatter.title}</li>
        </Link>
      );
    }
    return null;
  }
}
class WearableSection extends React.Component {
  render() {
    return (
      <div>
        <li className="hiddenSideLink">Wearable Words</li>
        <WearableLink checker={this.props.checker} />
      </div>
    );
  }
}
class CollectionLink extends React.Component {
  render() {
    if (this.props.checker.node.frontmatter.belongsTo === "collections") {
      return (
        <Link
          to={this.props.checker.node.frontmatter.path}
          className="hiddenSideLink"
        >
          <li className="sidee">{this.props.checker.node.frontmatter.title}</li>
        </Link>
      );
    }
    return null;
  }
}
class CollectionSection extends React.Component {
  render() {
    return (
      <div>
        <li className="hiddenSideLink">Collection</li>
        <CollectionLink checker={this.props.checker} />
      </div>
    );
  }
}
class CollaborationLink extends React.Component {
  render() {
    if (this.props.checker.node.frontmatter.belongsTo === "collaboration") {
      return (
        <Link
          to={this.props.checker.node.frontmatter.path}
          className="hiddenSideLink"
        >
          <li className="sidee">{this.props.checker.node.frontmatter.title}</li>
        </Link>
      );
    }
    return null;
  }
}
class CollaborationSection extends React.Component {
  render() {
    return (
      <div>
        <li className="hiddenSideLink">Collaborations</li>
        <CollaborationLink checker={this.props.checker} />
      </div>
    );
  }
}
class NavigationQuery extends React.Component {
  render() {
    if (this.props.checker.node.frontmatter.belongsTo === "wearable") {
      return <WearableSection checker={this.props.checker} />;
    }
    if (this.props.checker.node.frontmatter.belongsTo === "collections") {
      return <CollectionSection checker={this.props.checker} />;
    }
    if (this.props.checker.node.frontmatter.belongsTo === "collaboration") {
      return <CollaborationSection checker={this.props.checker} />;
    }
    return null;
  }
}

class NavHolder extends React.Component {
  render() {
    if (this.props.black) {
      return <div className="mobileNavHolderBlack">{this.props.children}</div>;
    } else {
      return <div className="mobileNavHolder">{this.props.children}</div>;
    }
  }
}

class BurgerChanger extends React.Component {
  render() {
    if (this.props.black) {
      return (
        <img
          onClick={this.toggleNavbar}
          className="mobileNavEndImage"
          src={BurgerWhite}
          alt=""
        />
      );
    } else {
      return (
        <img
          onClick={this.toggleNavbar}
          className="mobileNavEndImage"
          src={Burger}
          alt=""
        />
      );
    }
  }
}

class LogoChanger extends React.Component {
  render() {
    if (this.props.black) {
      return <img src={BlackLogo} className="mobileNavStartImage" alt="" />;
    } else {
      return <img src={Logo} className="mobileNavStartImage" alt="" />;
    }
  }
}

export default class MobileNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: false
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }
  render() {
    return (
      <section className="">
        <NavHolder black={this.props.black}>
          <div className="mobileNavStart">
            <Link to="/">
              <LogoChanger black={this.props.black}></LogoChanger>
            </Link>
          </div>
          <div onClick={this.toggleNavbar} className="mobileNavEnd">
            <BurgerChanger black={this.props.black}></BurgerChanger>
          </div>
        </NavHolder>
        <UnmountClosed isOpened={this.state.collapsed}>
          <section className="hiddenSide">
            <ul>
              <li className="hiddenSideLink">About</li>
              <Link to="/about" className="hiddenSideLink">
                <li className="sidee">Designer</li>
              </Link>
              <Link to="/sketches" className="hiddenSideLink">
                <li className="sidee">Sketches</li>
              </Link>
              <li className="hiddenSideLink">Wearable Words</li>
              <MobileWearable />
              <li className="hiddenSideLink">Collections</li>
              <MobileCollection />
              <li className="hiddenSideLink">Collaborations</li>
              <MobileCollaboration />
              <Link to="/contactus" className="hiddenSideLink">
                <li className="">Contact Us</li>
              </Link>
              <Link to="/news" className="hiddenSideLink">
                <li>News</li>
              </Link>
            </ul>
          </section>
        </UnmountClosed>
      </section>
    );
  }
}

import React from "react";
import "../styles/hybridStyles.css";
import Logo from "../../static/logo.svg";
import WhiteLogo from "../../static/logowhite.svg";
import { Link } from "gatsby";

import Navigation from "../components/navigation";
import MobileNavbar from "../components/mobileNavbar";
import Media from "react-media";
import Email from "../../static/email.svg";
import Facebook from "../../static/facebook.svg";
import Instagram from "../../static/instagram.svg";
import Helmet from "react-helmet";
import Favicon from "../../static/favicon.ico";

class ChangingLogo extends React.Component {
  render() {
    if (this.props.whiteLogo) {
      return (
        <Link className="logoBar" to="/">
          <img src={WhiteLogo} className="" alt="" />
        </Link>
      );
    }
    return (
      <Link className="logoBar" to="/">
        <img src={Logo} className="" alt="" />
      </Link>
    );
  }
}

class ChangingBackground extends React.Component {
  render() {
    if (this.props.black) {
      return (
        <div className="childContent hero blackBackground">
          {this.props.children}
        </div>
      );
    } else {
      return <div className="childContent hero">{this.props.children}</div>;
    }
  }
}

class Layout extends React.Component {
  render() {
    let tag = "blackTag";
    if (this.props.pinktag) {
      tag = "pinkTag";
    }

    let sets = "hero is-fullheight header-image thatLayout";
    if (this.props.blackColor) {
      sets += " thatLayoutBlack";
    }
    let footer = "footText";
    if (this.props.blackColor) {
      footer += " whiteFooter";
    } else {
      footer += " blackFooter";
    }

    const styles = {
      backgroundImage: `url(${this.props.backgroundimage})`
    };
    const miniLogoStyle = {
      backgroundColor: `${this.props.miniLogoColor}`
    };
    return (
      <div className="wholeThing">
        <Helmet>
          <title>Joyce Rouhana</title>
          <meta name="Description" content="Tailored jewelry!" />
          <link rel="icon" type="image/png" href={Favicon} sizes="16x16" />
        </Helmet>
        <div style={styles} className={sets}>
          <Media query="(min-width: 991px)">
            {matches =>
              matches ? (
                <div className="hero-head holder">
                  <ChangingLogo
                    className="top"
                    whiteLogo={this.props.whiteLogo}
                  ></ChangingLogo>

                  <Navigation
                    whiteNav={this.props.whiteNav}
                    className="layoutNav top"
                  ></Navigation>
                </div>
              ) : (
                <div className="hero-head holder">
                  <MobileNavbar
                    black={this.props.blackColor}
                    className="mobileN"
                  ></MobileNavbar>
                </div>
              )
            }
          </Media>
          <ChangingBackground>{this.props.children}</ChangingBackground>

          <div class="hero-foot thatFoot">
            <div className={footer}>
              <p>
                <span className="copyright">&copy;</span>{" "}
                {new Date().getFullYear()} Joyce Rouhana Jewelry | Developed by{" "}
                <a
                  className={tag}
                  rel="noopener noreferrer"
                  href="https://247studios.me/"
                >
                  247studios
                </a>
              </p>
            </div>
            <div className="logos">
              <a
                href="mailto:joycerouhana41@icloud.com?Subject=Hello There!"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img className="miniLogo" src={Email} alt="" />
              </a>
              <a
                href="https://www.facebook.com/joycerouhanajewelry/"
                rel="noopener noreferrer"
              >
                <img className="miniLogo" src={Facebook} alt="" />
              </a>
              <a
                href="https://www.instagram.com/joycerouhanajewelry/"
                rel="noopener noreferrer"
              >
                <img className="miniLogo" src={Instagram} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Layout;

import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
class CollaborationLink extends React.Component {
  render() {
    if (this.props.checker.node.frontmatter.belongsTo === "collaboration") {
      return (
        <Link
          to={this.props.checker.node.frontmatter.path}
          className="hiddenSideLink"
        >
          <li className="sidee">{this.props.checker.node.frontmatter.title}</li>
        </Link>
      );
    }
    return null;
  }
}

export default class MobileCollaboration extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query collaborationsubmobileQuery {
            allMarkdownRemark {
              edges {
                node {
                  html
                  id
                  frontmatter {
                    title
                    path
                    belongsTo
                    Description
                    heartImage
                    Carousel {
                      carouselImage
                    }
                    Cards {
                      cardImage
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div>
            {data.allMarkdownRemark.edges.map(item => (
              <div>
                <CollaborationLink checker={item} />
              </div>
            ))}
          </div>
        )}
      />
    );
  }
}

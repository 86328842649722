import React from "react";
import { Link } from "gatsby";
import Dropdown from "./dropdown";
import CollabDropdown from "./collabDrop";
import WearDrop from "./wearDrop";
import AboutDrop from "./aboutDrop";
import Heart from "../../static/heart.svg";
class Navigation extends React.Component {
  render() {
    let aTag = "column linky spacing";
    if (this.props.whiteNav) {
      aTag += " whiteColor";
    }
    return (
      <div className="container navC">
        <ul className="theNav columns is-mobile is-centered">
          <li className="listEl midSide">
            <AboutDrop whiteNav={this.props.whiteNav}></AboutDrop>
          </li>
          <li className="listEl midSide">
            <WearDrop whiteNav={this.props.whiteNav}></WearDrop>
          </li>
          <li className="listEl midSide">
            <Dropdown whiteNav={this.props.whiteNav}></Dropdown>
          </li>
          <li className="listEl midSide">
            <CollabDropdown whiteNav={this.props.whiteNav}></CollabDropdown>
          </li>
          <li className="listEl midSide">
            <Link className="thatLink" to="contactus">
              <a className={aTag}>Contact Us</a>
            </Link>
            <img className="heartSVG movedSVG" src={Heart} alt="" />
          </li>
          <li className="listEl lastSide">
            <Link className="thatLink" to="news">
              <a className={aTag}>News</a>
            </Link>
            <img className="heartSVG newsHeartSVG" src={Heart} alt="" />
          </li>
        </ul>
      </div>
    );
  }
}

export default Navigation;

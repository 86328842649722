import React from "react";
import "bulma/css/bulma.css";
import { Link } from "gatsby";
import Heart from "../../static/heart.svg";
import { StaticQuery, graphql } from "gatsby";
class QueryLink extends React.Component {
  render() {
    if (this.props.checker.node.frontmatter.belongsTo === "collections") {
      if (
        this.props.checker.node.frontmatter.path ===
        "/Birthstoneshamballabraclets"
      )
        return (
          <li className="listElD showMeM">
            <Link className="" to={this.props.checker.node.frontmatter.path}>
              <a className="column linky up linkyA">
                {this.props.checker.node.frontmatter.title}
              </a>
            </Link>
          </li>
        );
      return (
        <li className="listElD showMeM">
          <Link className="" to={this.props.checker.node.frontmatter.path}>
            <a className="column linky linkyA">
              {this.props.checker.node.frontmatter.title}
            </a>
          </Link>
        </li>
      );
    }
    return null;
  }
}

class Dropdown extends React.Component {
  render() {
    let adTag = "column firstD sideUp";
    if (this.props.whiteNav) {
      adTag += " whiteColor";
    }
    return (
      <ul className="thyNav columns is-mobile is-centered">
        <img className="heartSVGD movedSVGD" src={Heart} />
        <div className="moveUp">
          <li className="theShowOff thatLink">
            <span className="sideUp">
              <a className={adTag}>
                <span className="evenMore">Collections</span>
              </a>
            </span>
          </li>
          <div className="showMe">
            <StaticQuery
              query={graphql`
                query collectionQuery {
                  allMarkdownRemark {
                    edges {
                      node {
                        html
                        id
                        frontmatter {
                          title
                          path
                          belongsTo
                          Description
                          heartImage
                          Carousel {
                            carouselImage
                          }
                          Cards {
                            cardImage
                          }
                        }
                      }
                    }
                  }
                }
              `}
              render={data => (
                <div>
                  {data.allMarkdownRemark.edges.map(item => (
                    <div>
                      <QueryLink checker={item} />
                    </div>
                  ))}
                </div>
              )}
            />
          </div>
        </div>
      </ul>
    );
  }
}

export default Dropdown;

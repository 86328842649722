import React from "react";
import "bulma/css/bulma.css";
import { Link } from "gatsby";
import Heart from "../../static/heart.svg";

class Dropdown extends React.Component {
  render() {
    let adTag = "column firstD sideUp";
    if (this.props.whiteNav) {
      adTag += " whiteColor";
    }
    return (
      <ul className="thyNav columns is-mobile is-centered">
        <img className="heartSVGD movedSVGDA" src={Heart} alt="heart" />
        <div className="moveUp">
          <li className="theShowOff thatLink">
            <span className="sideUp">
              <a className={adTag}>
                <span className="evenMore">About</span>
              </a>
            </span>
          </li>
          <div className="showMeDA">
            <li className="listElD showMeM">
              <Link className="" to="/about">
                <a className="column linky linkyA smallFont">Designer</a>
              </Link>
            </li>
            <li className="listElD showMeM">
              <Link className="" to="/sketches">
                <a className="column linky linkyA smallFont">Sketches</a>
              </Link>
            </li>
          </div>
        </div>
      </ul>
    );
  }
}

export default Dropdown;
